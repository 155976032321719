<template>
  <div dir="ltr" class="customize__index">
    <!-- Loader -->
    <div v-if="isLoading">
      <LoaderMain v-if="isLoading" />
    </div>

    <!-- All Pages -->
    <div v-else>
      <div v-if="KeyNotValid">
        <transition name="quizFade" appear>
          <InvalidKeyPage />
        </transition>
      </div>
      <div v-else>
        <div v-if="getPreviewSettings && getPreviewSettings.expireStatus">
          <transition name="quizFade" appear>
            <ExpiredPage />
          </transition>
        </div>
        <!-- <preview-nav  :changeView="changeView" :key="count"></preview-nav> -->
        <div v-else>
          <!-- <transition name="quizFade"> -->
          <div
            v-if="CurrentSelectedPage.value == 'getStartedPage'"
            class="row m-0 px-2"
            style="overflow-x: hidden"
            dir="ltr"
            :key="countComp + 'getStarted'"
          >
            <!-- <div style="display: contents; height: 100dvh;width:100vw;"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">

                
                <GetStartedPreview
                style="margin:5px;"
                  :isDesktopView="isDesktopView"
                  :apiToken="QuizKey"
                  :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              RoutePage="QuizPreview"
                />
              </div>
            <!-- </div> -->
          </div>

          <div
            v-if="CurrentSelectedPage.value == 'singleAnswer'"
            class="row m-0 px-2"
            style="overflow-x: hidden"
            dir="ltr"
            :key="countComp + 'single'"
          >
            <!-- <div style="display: contents; height: 100vh"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">
                
                <KozimaSinglePageComp v-if="isKozimaGameQuestion" style="margin:5px;"
                  :isDesktopView="isDesktopView"
                  :apiToken="QuizKey"
                  :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              RoutePage="QuizPreview"></KozimaSinglePageComp>
                <SingleAnswerPreview v-else
                style="margin:5px;"
                  :isDesktopView="isDesktopView"
                  :apiToken="QuizKey"
                  :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              RoutePage="QuizPreview"
                />
                
                 
               
              </div>
            <!-- </div> -->
          </div>

          <div
            v-if="CurrentSelectedPage.value == 'multipleAnswer'"
            class="row m-0 px-2"
            style="overflow-x: hidden"
            dir="ltr"
            :key="countComp + 'multi'"
          >
            <!-- <div style="display: contents; height: 100vh"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">
                <MultipleAnswerPreview
                style="margin:5px;"
                  :isDesktopView="isDesktopView"
                  :apiToken="QuizKey"
                  :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              RoutePage="QuizPreview"
                />
              <!-- </div> -->
            </div>
          </div>

          <div
            v-if="CurrentSelectedPage.value == 'leadPage'"
            class="row m-0 px-2"
            style="overflow-x: hidden"
            dir="ltr"
            :key="countComp + 'lead'"
          >
            <!-- <div style="display: contents; height: 100vh"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">
                <LeadPreview
                style="margin:5px;"
                  :isDesktopView="isDesktopView"
                  :apiToken="QuizKey"
                  :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              RoutePage="QuizPreview"
                />
              </div>
            <!-- </div> -->
          </div>

          <div
            v-if="CurrentSelectedPage.value == 'resultPage'"
            class="row m-0 "
            :class="checkisElthClient ? null : 'px-2'"
            style="overflow-x: hidden"
            dir="ltr"
            :key="countComp + 'result'"
          >  
          <!-- <div style="display: contents; height: 100vh"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">
            <ResultPreview
            style="margin:5px;"
              :isDesktopView="isDesktopView"
              :apiToken="QuizKey"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :resultPreviewProducts="resultPreviewProducts"
              RoutePage="QuizPreview"
            />
          </div>
          <!-- </div> -->
          </div>

          <div
            v-if="CurrentSelectedPage.value == 'stripePage'"
            class="row m-0 px-2"
            style="overflow-x: hidden"
            dir="ltr"
            :key="countComp + 'stripe'"
          >
            <!-- <div style="display: contents; height: 100vh"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">
                <StripePreview
                style="margin:5px;"
                  :isDesktopView="isDesktopView"
                  :apiToken="QuizKey"
                  :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              RoutePage="QuizPreview"
                />
              </div>
            <!-- </div> -->
          </div>
          <div
            v-if="CurrentSelectedPage.value == 'sliderPage'"
            class="row m-0"
            
            dir="ltr"
            :key="countComp + 'slider'"
          >
          <!-- <div style="display: contents; height: 100vh"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">
             <SliderComp
             style="margin:5px;"
              :isDesktopView="isDesktopView"
              :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
              :apiToken="QuizKey"
              RoutePage="QuizPreview"
              
            />
          </div>
            <!-- </div> -->
           
          </div>

          <div
            v-if="CurrentSelectedPage.value == 'endQuiz'"
            class="row m-0 px-2"
            style="overflow-x: hidden"
            dir="ltr"
            :key="countComp + 'endQuiz'"
          >
            <!-- <div style="display: contents; height: 100vh"> -->
              <div style="display: contents; height: 100dvh;width:100vw;" class="col-12 d-flex justify-content-center p-0 mt-0 align-items-center">
                <EndQuizPreview
                style="margin:5px;"
                  :isDesktopView="isDesktopView"
                  :animationType="getIsAnimationEnabled ? getAnimationType :'zoom-in'"
              :getIsAnimationEnabled="getIsAnimationEnabled"
              :getAnimationSpeedinMillis="getAnimationSpeedinMillis"
                  :apiToken="QuizKey"
                  RoutePage="QuizPreview"
                />
              </div>
            <!-- </div> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
export default {
  components: {
   
    LoaderMain:()=> import(/* webpackPrefetch: true */ '../../components/LoaderComps/LoaderMain.vue'),
    KozimaSinglePageComp: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/specialClients/KozimaSinglePageComp.vue"
      ),
    GetStartedPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/getStartedComp.vue"
      ),
    SingleAnswerPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/SingleAnswerComp.vue"
      ),
    MultipleAnswerPreview: () =>
      import(
        /* webpackPrefetch: true */"../quizPreviewComp/MultipleAnswerComp.vue"
      ),
    LeadPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/LeadComp.vue"
      ),
    ResultPreview: () =>
      import(
        /* webpackPrefetch: true */"../quizPreviewComp/ResultComp.vue"
      ),
    EndQuizPreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/EndQuiz.vue"
      ),
    InvalidKeyPage: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/InvalidKeyPage.vue"
      ),
    ExpiredPage: () =>
      import(
       /* webpackPrefetch: true */"../quizPreviewComp/ExpiredPage.vue"
      ),
    StripePreview: () =>
      import(
        /* webpackPrefetch: true */ "../quizPreviewComp/StripePreviewComp.vue"
      ),
      SliderComp: () => import(/* webpackPrefetch: true */ '../quizPreviewComp/SliderComp.vue'),
  },
  data() {
    return {
      count: 0,
      countComp: 0,
      isDesktopView: true,
      isLoading: false,
      KeyNotValid: false,
      QuizKey: "",
      windowWidth: window.innerWidth,
      animationType: "SlideIn",
      isResultPreview: false,
      resultPreviewProducts: [],
    };
  },
  computed: {
    isKozimaGameQuestion(){
      if(this.getEnvConst?.KOZIMA_GAME){
        const kozima = JSON.parse(this.getEnvConst?.KOZIMA_GAME)[0]
        return kozima.quizId==this.getPreviewSettings.quizId
      }
      else {
        return false
      }
    },
    getEnvConstants(){
      return this.getPreviewSettings?.env_contants ? this.getPreviewSettings?.env_contants : null
    },
    isrealChartClientIds(){
      if(this.getEnvConstants){
        return [
        parseInt(this.getEnvConstants?.VUE_APP_ISRAELI_CHART_QUIZID??null),
        parseInt(this.getEnvConstants?.VUE_APP_ISRAELI_CHART_2_QUIZID??null),
      ]
      }
      else {
        return []
      }
    },

    checkisElthClient(){
     if(this.getEnvConstants){
      if (this.getPreviewSettings.quizId == this.getEnvConstants?.VUE_APP_ITALIAN_QUIZID??null) return true
     if (this.getPreviewSettings.quizId == this.getEnvConstants?.VUE_APP_ELTH_QUIZID??null) return true
      if (this.getPreviewSettings.quizId == this.getEnvConstants?.VUE_APP_ELTH_V3_QUIZID??null) return true;
     
      return false
     }
     else{
      return false
     }
      
    },
    ...mapGetters([
      "getSelectedPage",
      "getTotalPages",
      "getPreviewSettings",
      "getSelectedOptions",
      "getTotalPreviewPages",
      "getSavedLeadID",
      "getEnvConst"
    ]),
    CurrentSelectedPage() {
      
      return this.getSelectedPage;
    },
    getSelectedOptionsFromVuex() {
      return JSON.parse(JSON.stringify(this.getSelectedOptions));
    },

    isExpired() {
      if (this.getPreviewSettings) return this.getPreviewSettings.expireStatus;
      return null;
    },
    getIsAnimationEnabled() {
      if (this.getPreviewSettings)
        return this.getPreviewSettings.animation.show;
      return null;
    },
    getAnimationType() {
      if (this.getPreviewSettings) {
        let { direction } = this.getPreviewSettings.animation;

        if (direction == "LTR") return `slide-right`;
        if (direction == "RTL") return `slide-left`;
        if (direction == "TTB") return `slide-down`;
        if (direction == "BTT") return `slide-up`;
        if (direction == "POP") return `zoom-in`;
        if (direction == "FLIP") return `flip-left`;
      }
      return null;
    },
    getAnimationSpeedinMillis() {
      if (this.getPreviewSettings) {
        let { speed, show } = this.getPreviewSettings.animation;

        if (typeof speed === "string" && show) {
          speed = parseFloat(speed);
          if (isNaN(speed)) {
            return 0;
          }
        } else {
          speed = 0.3;
        }

        return speed * 1000;
      }
      return null;
    },
    GetCurrPageIndex() {
      if (this.getTotalPreviewPages && this.CurrentSelectedPage) {
        let index = this.getTotalPreviewPages.findIndex(
          (page) => page.id == this.CurrentSelectedPage.id
        );
        return index > -1 ? index : null;
      } else {
        return null;
      }
    },
  },
  async created() {
  try {
    window.addEventListener("resize", this.resizeHandler);

    const quizKey = this.$route.params.id
    const result_key = this.$route.query.result_key;
    this.QuizKey = quizKey;

    this.initializeComponentState();
    if (result_key) {
      await this.PopulateResultPreviewDataVuex(result_key);
    } 
  
    else {
      await this.initializePreviewData();
      this.resetSavedLeadObj({
       address:{},
       custom_inputs:{},
            date:'',
            email:'',
            freeTextNote:[],
            full_name:'',
            website:'',
            organisation:'',
            phone_number:'',
            terms_conditions:false, fileUpload:[],
            quiz_analytic_id:null})
            this.resetLeadCounter()
            this.setIsLoadingQuestion(false)
    }

  } catch (error) {
    this.$toasted.show(
            "An error occurred. Please try again.",
            {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            }
          );
          throw error
  }
},
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },

  mounted() {
  
    if (this.windowWidth >= 576) {
      this.isDesktopView = true;
    } else {
      this.isDesktopView = false;
    }
    if (localStorage.getItem("already_record_status")) {
      localStorage.removeItem("already_record_status");
    }
    if (localStorage.getItem("already_record_email")) {
      localStorage.removeItem("already_record_email");
    }
    if (localStorage.getItem("already_record_quiz_continue")) {
      localStorage.removeItem("already_record_quiz_continue");
    }
  },

  methods: {
    
     // Grouping the reset and initialization logic
  initializeComponentState() {
    this.RESET_STATE();
    this.ResetStoredData();
    this.resetSelectedOptions();
    this.resetRangeSliders();
    this.resetPreviewPagesStack();
    this.resetPopulateTrackCounter();
    console.log(this.$route.query.lang)
    this.resetSavedLeadObj({
       address:{},
       custom_inputs:{},
            date:'',
            email:'',
            freeTextNote:[],
            full_name:'',
            website:'',
            organisation:'',
            phone_number:'',
            terms_conditions:false, fileUpload:[],
            quiz_analytic_id:null})
  },

  // Separating the data population logic
  async initializePreviewData() {
    await this.PopulatePreviewDataVuex(this.QuizKey);
    await this.resetSelectedOptions();
    await this.resetRangeSliders();
  },



    async updateQuizAnalyses() {
      try {
        let data = {
          api_token: this.QuizKey,
          quizId: this.getPreviewSettings.quizId,
          value_type: "Started",
        };

      await  this.$store.dispatch(`updateQuizAnalysesAction`,data)
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },
    resizeHandler(e) {
      if (e.target.window.innerWidth > 650) {
        this.isDesktopView = true;
      } else {
        this.isDesktopView = false;
      }
    },
    ...mapMutations([
      "setIsLoadingQuestion",
       "resetLeadCounter",
      "updatePageCounter",
      "ResetStoredData",
      "setSelectedPage",
      "setPreviewProgressBar",
      "setTotalPreviewPages",
      "setPreviewSettings",
      "resetSelectedOptions",
      "resetRangeSliders",
      "resetPreviewPagesStack",
      "RESET_STATE",
      "setTranslation",
      "resetQuizStarted",
      "resetSavedLeadObj",
      "resetPopulateTrackCounter",
      "setSavedLeadID",
      "populateSetSelectedOptions",
      "assignQuizAnaylticsId"
    ]),

    async PopulatePreviewDataVuex(QuizKey) {
      this.isLoading = true;
      this.resetQuizStarted();
      try {
        let lang=this.$route.query.lang;
        let data = {
          api_token: QuizKey,
          lang:lang
        };
        let firstPage;
        const response = await axios.get(`/api/loadQuiz`, { params: data });
        if (response.status == 200 && response.data.status == "success") {
          const pageCounter = response.data.data?.page_counter ;
          const totalPages = this.rearrangeOrder(response.data.data.totalPages);
          const progressBarValue = response.data.data.progressBar;
          const previewSettings = response.data.data.setting;
          window.quizell.allQuestions = totalPages;
          if (totalPages.length > 0) {
            firstPage = totalPages[0];
          }
          await this.setTotalPreviewPages(totalPages);
          await this.setSelectedPage(JSON.parse(JSON.stringify(firstPage)));
          await this.setPreviewProgressBar(progressBarValue);
          await this.setTranslation(previewSettings.translation);
          await this.setPreviewSettings(previewSettings);
          await this.updatePageCounter(pageCounter)
          // this.setAnimationSpeed();
        } else if (response.status == 200 && response.data.status == "fail") {
          this.KeyNotValid = true;
        }
      } catch (error) {
        this.KeyNotValid = true;
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    async getBlocksArrayForIsrealChartClient() {
      try {
        const blocksArray = await axios.get(`/api/loadQuiz`, {
          params: {
            api_token: this.QuizKey,
          },
        });
        return blocksArray.data.data.totalPages;
      } catch (error) {
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      }
    },
    async PopulateResultPreviewDataVuex(resultKey) {
      this.isLoading = true;
      this.resetQuizStarted();
      try {
        let data = {
          result_key: resultKey,
        };
        let firstPage;
        const response = await axios.post(`/api/getQuizResultHistory`, data);
        if (response.status == 200 && response.data.status == "success") {
          if (this.$route.query.email) {
            localStorage.setItem(
              "already_record_email",
              this.$route.query.email
            );
            localStorage.setItem("already_record_status", true);
          }
          this.isResultPreview = true;
          const totalPages = [response.data.data.result_page];
          const previewSettings = response.data.data.setting;
          const leadsData = response.data.data.lead;
          this.resultPreviewProducts = response.data.data.products;

          if (response.data.data?.questionAnswers) {
            this.populateSetSelectedOptions(
              response.data.data?.questionAnswers
            );
          }

          window.quizell.allQuestions = totalPages;
          if (totalPages.length > 0) {
            firstPage = totalPages[0];
          }
          await this.setTotalPreviewPages(totalPages);
          await this.setSelectedPage(JSON.parse(JSON.stringify(firstPage)));

          if (
            this.isrealChartClientIds.includes(
              response.data.data.setting.quizId
            )
          ) {
            let dataBlocks = await this.getBlocksArrayForIsrealChartClient();
            await this.setTotalPreviewPages(dataBlocks);
          }
          await this.setSavedLeadID(leadsData);
          // await this.setPreviewProgressBar(progressBarValue);
          await this.setTranslation(previewSettings.translation);
          await this.setPreviewSettings(previewSettings);
          // this.setAnimationSpeed();
        } else if (response.status == 200 && response.data.status == "fail") {
          this.KeyNotValid = true;
        }
      } catch (error) {
        this.KeyNotValid = true;
        this.$toasted.show("Error occured ", {
          theme: "toasted-primary",
          type: "error",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.isLoading = false;
      }
    },
    rearrangeOrder(arr) {
      const tempArr = [...arr];
      const newArr = tempArr.sort((a, b) => a.order - b.order);
      return newArr;
    },
  },
  watch: {
    getTotalPreviewPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
    },
    CurrentSelectedPage() {
      this.countComp++;
      window.quizell.selectedQuestionOptions =
        this.getSelectedOptionsFromVuex.questionAnswer;
    },
    GetCurrPageIndex(newVal, oldVal) {
      let { quiz_analytic_id} = this.getSavedLeadID
      if (newVal == 1 && oldVal == 0 && !quiz_analytic_id) {
        this.updateQuizAnalyses();
      }
    },
  },
};
</script>

<style>
.default-bg {
  background: #cccace;
}
.cursorPointer {
  cursor: pointer;
}
.div-disable {
  pointer-events: none;
  opacity: 0.4;
}
.image-division {
  height: 100%;
  /* display: flex;
    align-items: center;
    justify-content: center; */
}
.ql-editor {
  min-height: auto !important;
  height: auto !important;
}
.ql-container {
  height: auto !important;
  min-height: auto !important;
}
.sub-title {
  font-size: 14px;
  color: #8c898f;
}
.page-setting-btn,
.page-setting-btn i {
  color: #8c898f;
  font-weight: 500;
}
.close-icon {
  position: absolute;
  right: 23px;
  top: 23px;
  background: transparent;
  border: none;
}
.deleteBlock {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  z-index: 10;
}

.SelectedImageStyle {
  position: absolute;
  top: 0;
  right: 0;
}
.ImageInputBlock:hover {
  opacity: 0.6;
  /* background-color:rgba(255, 255, 255, 0.3) !important; */
}
#preview div {
  word-break: break-word !important;
}
.navbar__icon-container.active {
  background: rgb(179 175 182 / 30%) !important;
}

.big-div {
  /* box-shadow: 0px 4px 10px rgb(0 0 0 / 15%); */

  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 0px;
}

.Tab:hover {
  transform: translate3d(0, -3px, 0);
  -webkit-transform: translate3d(0, -3px, 0);
}
</style>
